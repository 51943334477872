import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { Badge } from "nlib/ui";
import { draggable } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { getTextsData } from "selectors/texts";
import { getUserData } from "selectors/user";
import { useDispatch, useSelector } from "react-redux";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import TasksActions from "actions/TasksActions";
import UserRoles from "const/UserRoles";
import classNames from "classnames";
import useEnvVars from "hooks/useEnvVars";
import useShowCommonModal from "hooks/useShowCommonModal";
import useTaskContent from "hooks/useTaskContent";

const SQUARE_POWER = 2;

const MAX_CLICK_DISTANCE = 5;

const TITLE_MAX_LENGTH = 56;

const Card = ({ taskData }) => {
  const [, setEnvVars] = useEnvVars();

  const clientClickStartCoordRef = useRef(null);

  const {
    id: taskId,
    createdBy,
    comments,
    attachmentsCount
  } = taskData;

  const {
    startDateContent,
    dueDateContent,
    dueDateStatus,
    createdByContent,
    createdAtContent,
    taskForContent
  } = useTaskContent(taskData);

  const title = taskData.title || taskData.preview || "";

  const elementRef = useRef(null);

  const dispatch = useDispatch();

  const { id: userId, role: userRole } = useSelector(getUserData);

  const { uiTexts, messages } = useSelector(getTextsData);

  const showCommonModal = useShowCommonModal();

  const [dragging, setDragging] = useState(false);

  const createdByAtContent = useMemo(() => {
    return [createdBy && createdByContent, createdAtContent].filter(Boolean).join(", ");
  }, [createdBy, createdByContent, createdAtContent]);

  const canBeManaged = useMemo(() => {
    return (userRole === UserRoles.ACCOUNTANT_ADMIN.roleId || (createdBy && createdBy.id === userId));
  }, [userRole, createdBy, userId]);

  const handleRemoveMouseDown = useCallback((event) => {
    event.stopPropagation();
  }, []);

  const handleMouseDown = useCallback((event) => {
    event.stopPropagation();

    const { clientX, clientY } = event;

    clientClickStartCoordRef.current = { clientX, clientY };
  }, []);

  const handleMouseUp = useCallback(({ clientX, clientY }) => {
    if (!clientClickStartCoordRef.current) return;

    const distance = Math.sqrt(
      Math.pow(clientX - clientClickStartCoordRef.current.clientX, SQUARE_POWER)
      + Math.pow(clientY - clientClickStartCoordRef.current.clientY, SQUARE_POWER)
    );

    clientClickStartCoordRef.current = null;

    if (distance > MAX_CLICK_DISTANCE) return;

    setEnvVars({ editItem: null, status: null, editTask: taskId });
  }, [setEnvVars, taskId]);

  const handleRemoveClick = useCallback(async(event) => {
    event.stopPropagation();

    let result = await showCommonModal({ confirm: true, text: messages.taskDeleteConfirm });

    if (!result) return;

    result = await dispatch(TasksActions.deleteTask(taskId));
  }, [taskId, messages, dispatch, showCommonModal]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    return draggable({
      element: elementRef.current,
      getInitialData: () => ({ taskId }),
      onDragStart: () => setDragging(true),
      onDrop: () => setDragging(false)
    });
  }, [taskId]);

  return (
    <div
      ref={elementRef}
      className={classNames(Css.card, dragging && Css.dragging)}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}>
      <div className={Css.preview}>
        {`${title?.slice(0, TITLE_MAX_LENGTH)}${title?.length > TITLE_MAX_LENGTH ? "..." : ""}`}
      </div>
      <div className={Css.createdBy}>
        <div title={createdByAtContent}>{createdByAtContent}</div>
      </div>
      <div className={Css.info}>
        {!!startDateContent && (
          <div className={Css.infoItem}>
            <div className={Css.infoItemTitle}>{uiTexts.startDate}</div>
            <div className={Css.infoItemValue} title={startDateContent}>
              <Badge>{startDateContent}</Badge>
            </div>
          </div>
        )}

        {dueDateContent && (
          <div className={Css.infoItem}>
            <div className={Css.infoItemTitle}>{uiTexts.deadline}</div>
            <div className={Css.infoItemValue} title={dueDateContent}>
              <Badge theme={dueDateStatus}>{dueDateContent}</Badge>
            </div>
          </div>
        )}

        <div className={Css.infoItem}>
          <div className={Css.infoItemTitle}>{uiTexts.taskFor}</div>
          <div className={Css.infoItemValue} title={taskForContent}>
            <Icons.User />
            <span>{taskForContent}</span>
          </div>
        </div>

        <div className={Css.infoItem}>
          <div className={Css.infoItemTitle}>{uiTexts.created}</div>
          <div className={Css.infoItemValue} title={createdAtContent}>
            <span>{createdAtContent}</span>
          </div>
        </div>

        {!!createdBy && !!createdByContent && (
          <div className={Css.infoItem}>
            <div className={Css.infoItemTitle}>{uiTexts.createdBy}</div>
            <div className={Css.infoItemValue} title={createdByContent}>
              <span>{createdByContent}</span>
            </div>
          </div>
        )}
      </div>
      <div className={Css.footer}>
        <div className={classNames(Css.item, Css.attachments)}>
          <Icons.FileText />
          <Badge counter>{attachmentsCount}</Badge>
        </div>
        <div className={classNames(Css.item, Css.comments)}>
          <Icons.ChatText />
          <Badge counter theme={comments.unread && "attention"}>{comments.all}</Badge>
        </div>
        <div className={Css.item}>
          {canBeManaged && (
            <div
              className={Css.remove}
              onMouseDown={handleRemoveMouseDown}
              onClick={handleRemoveClick}>
              <Icons.Trash />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(Card);
