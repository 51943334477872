import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { Badge } from "nlib/ui";
import { dropTargetForElements } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Card from "./lib/Card";
import Constants from "const/Constants";
import DataConstants from "const/DataConstants";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import classNames from "classnames";
import useEnvVars from "hooks/useEnvVars";

const Column = ({ status, tasksData }) => {
  const elementRef = useRef(null);

  const [, setEnvVars] = useEnvVars();

  const { uiTexts } = useSelector(getTextsData);

  const [draggedOver, setDraggedOver] = useState(false);

  const columnTasksData = useMemo(() => {
    return tasksData.filter((taskData) => taskData.status === status);
  }, [status, tasksData]);

  const handleAddClick = useCallback(() => {
    setEnvVars({ editTask: `${Constants.NEW_ENTITY_ID}.${status}` });
  }, [status, setEnvVars]);

  useEffect(() => {
    return dropTargetForElements({
      element: elementRef.current,
      getData: () => ({ status }),
      onDragEnter: () => setDraggedOver(true),
      onDragLeave: () => setDraggedOver(false),
      onDrop: () => setDraggedOver(false)
    });
  }, [status]);

  return (
    <div className={classNames(Css.column, draggedOver && Css.draggedOver)}>
      <div className={Css.header}>
        <div className={Css.title} data-status={status}>
          <span>{uiTexts[DataConstants.TASK_STATUSES_TO_UI_TEXT[status]]}</span>
          <Badge counter className={Css.badge}>{columnTasksData.length}</Badge>
        </div>
        <div
          className={Css.addButton}
          onClick={handleAddClick}>
          <Icons.PlusCircle />
        </div>
      </div>
      <div ref={elementRef} className={Css.list}>
        <div className={Css.scroll}>
          {columnTasksData.map((taskData) => {
            return <Card key={taskData.id} taskData={taskData} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default React.memo(Column);
