import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { Badge, Button, DropDown, Input } from "nlib/ui";
import {
  DropDownCaret,
  DropDownContent,
  DropDownMenuDivider,
  DropDownMenuItem,
  DropDownToggle
} from "nlib/ui/DropDown";
import { getSelectedBusinessUsersData } from "selectors/businesses";
import { getTasksData } from "selectors/tasks";
import { getTextsData } from "selectors/texts";
import { getUserData } from "selectors/user";
import { useSelector } from "react-redux";
import React, { Fragment, useCallback, useMemo, useState } from "react";
import UserRoles from "const/UserRoles";
import Utils from "utils/Utils";
import classNames from "classnames";
import useEnvVars from "hooks/useEnvVars";

const MIN_USERS_TO_SHOW_SEARCH = 6;

const TASK_TARGET_USERS = {
  ACCOUNTANT: "accountant",
  CLIENT: "client"
};

const FilterByUser = ({ modal, className, onSelect, ...restProps }) => {
  const [{ targetUser }, setEnvVars] = useEnvVars();

  const { uiTexts } = useSelector(getTextsData);

  const allTasksData = useSelector(getTasksData);

  const userData = useSelector(getUserData);

  const allUsersData = useSelector(getSelectedBusinessUsersData);

  const [search, setSearch] = useState("");

  const selectedFilterText = useMemo(() => {
    switch (targetUser) {
      case TASK_TARGET_USERS.ACCOUNTANT:
        return uiTexts.tasksForAccountant;
      case TASK_TARGET_USERS.CLIENT:
        return uiTexts.tasksForClient;
      default:
        if (targetUser === userData.id) return uiTexts.myTasks;

        return allUsersData.find(({ id }) => id === targetUser)?.fullName;
    }
  }, [allUsersData, targetUser, uiTexts, userData.id]);

  const anotherUsersData = useMemo(() => {
    return allUsersData.filter(({ id }) => id !== userData.id);
  }, [allUsersData, userData.id]);

  const filteredUsersData = useMemo(() => {
    if (!search) return anotherUsersData;

    const searchLower = search.toLowerCase().trim();

    return anotherUsersData.filter(({ fullName, email }) => fullName.toLowerCase().includes(searchLower)
      || email.toLowerCase().includes(searchLower));
  }, [anotherUsersData, search]);

  const myTasks = useMemo(() => {
    return allTasksData.filter((data) => data.targetUser?.id === userData.id);
  }, [allTasksData, userData.id]);

  const guestsUsersData = useMemo(() => {
    return Utils.arraySort(filteredUsersData.filter(({ guestUser }) => guestUser), "fullName", true);
  }, [filteredUsersData]);

  const commonUsersData = useMemo(() => {
    return Utils.arraySort(filteredUsersData.filter(({ guestUser }) => !guestUser), "fullName", true);
  }, [filteredUsersData]);

  const tasksForAccountantCount = useMemo(() => {
    return allTasksData.filter((data) => !data.targetUser || UserRoles.checkIsAccountant(data.targetUser?.role)).length;
  }, [allTasksData]);

  const tasksForClientCount = useMemo(() => {
    return allTasksData.filter((data) => UserRoles.checkIsBusiness(data.targetUser?.role)).length;
  }, [allTasksData]);

  const handleSearchChange = useCallback((value) => {
    setSearch(value);
  }, [setSearch]);

  const handleMenuItemClick = useCallback((value) => {
    setEnvVars({ targetUser: value });
    if (onSelect) onSelect(value);
  }, [setEnvVars, onSelect]);

  return (
    <DropDown className={classNames(Css.filterByUser, className)}>
      <DropDownToggle className={Css.toggle}>
        <Button title={selectedFilterText || uiTexts.selectUser} {...restProps}>
          <Icons.User />
          <span className={classNames(Css.label, !selectedFilterText && Css.placeholder)}>
            {selectedFilterText || uiTexts.selectUser}
          </span>
          <DropDownCaret />
        </Button>
      </DropDownToggle>
      <DropDownContent
        alignRight
        modal={modal}
        className={Css.dropDownContent}>
        <DropDownMenuItem
          className={classNames(Css.menuItem, Css.myTasks)}
          value={userData.id}
          selected={userData.id === targetUser}
          onClick={handleMenuItemClick}>
          <span className={Css.label}>{uiTexts.myTasks}</span>
          {!!myTasks.length && <Badge counter className={Css.badge}>{myTasks.length}</Badge>}
        </DropDownMenuItem>
        {allUsersData.length > 1 && (
          <>
            <DropDownMenuDivider className={Css.divider} />
            <DropDownMenuItem
              className={Css.menuItem}
              title={uiTexts.tasksForAccountant}
              value={TASK_TARGET_USERS.ACCOUNTANT}
              selected={TASK_TARGET_USERS.ACCOUNTANT === targetUser}
              onClick={handleMenuItemClick}>
              <span className={Css.label}>{uiTexts.tasksForAccountant}</span>
              {!!tasksForAccountantCount && <Badge counter className={Css.badge}>{tasksForAccountantCount}</Badge>}
            </DropDownMenuItem>
            <DropDownMenuItem
              className={Css.menuItem}
              title={uiTexts.tasksForClient}
              value={TASK_TARGET_USERS.CLIENT}
              selected={TASK_TARGET_USERS.CLIENT === targetUser}
              onClick={handleMenuItemClick}>
              <span className={Css.label}>{uiTexts.tasksForClient}</span>
              {!!tasksForClientCount && <Badge counter className={Css.badge}>{tasksForClientCount}</Badge>}
            </DropDownMenuItem>
            <DropDownMenuDivider className={Css.divider} />
            {allUsersData.length > MIN_USERS_TO_SHOW_SEARCH && (
              <Input
                cleanable
                autoFocus
                placeholder={uiTexts.searchUser}
                iconBefore={Icons.MagnifyingGlass}
                value={search}
                onChange={handleSearchChange} />
            )}
            {UserRoles.getRoles().map(({ roleId, langId }) => {
              const users = commonUsersData.filter(({ role }) => role === roleId);

              if (!users.length) return null;

              return (
                <Fragment key={roleId}>
                  <div className={Css.title}>{uiTexts[langId]}</div>
                  {users.map(({ id, fullName }) => {
                    const count = allTasksData.filter((data) => data.targetUser?.id === id).length;

                    return (
                      <DropDownMenuItem
                        key={id}
                        className={Css.menuItem}
                        title={fullName}
                        value={id}
                        selected={id === targetUser}
                        onClick={handleMenuItemClick}>
                        <span className={Css.label}>{fullName}</span>
                        {!!count && <Badge counter className={Css.badge}>{count}</Badge>}
                      </DropDownMenuItem>
                    );
                  })}
                </Fragment>
              );
            })}
            {!!guestsUsersData.length && <div className={Css.title}>{uiTexts.guestUsers}</div>}
            {guestsUsersData.map(({ id, fullName }) => {
              const count = allTasksData.filter((data) => data.targetUser?.id === id).length;

              return (
                <DropDownMenuItem
                  key={id}
                  className={Css.menuItem}
                  value={id}
                  selected={id === targetUser}
                  onClick={handleMenuItemClick}>
                  <span className={Css.label}>{fullName}</span>
                  {!!count && <Badge counter className={Css.badge}>{count}</Badge>}
                </DropDownMenuItem>
              );
            })}
          </>
        )}
      </DropDownContent>
    </DropDown>
  );
};

export default FilterByUser;
