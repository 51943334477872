import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Badge from "nlib/ui/Badge";
import Constants from "const/Constants";
import DataConstants from "const/DataConstants";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Utils from "utils/Utils";
import classNames from "classnames";
import moment from "moment";
import useEnvVars from "hooks/useEnvVars";
import useWindowResize from "hooks/useWindowResize";

const { DATETIME_FORMATS: { MONTH_AND_YEAR_TEXT_EXT } } = Constants;

const COMMON_TAB_WIDTH = 128;

const Tab = ({ data: { label, fromDate, toDate, count } = {}, showBadge = true, active, onActive, onClick }) => {
  const ref = useRef();

  const handleClick = useCallback(() => {
    onClick({ fromDate, toDate });
  }, [fromDate, toDate, onClick]);

  useEffect(() => {
    if (active) onActive(ref.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      ref={ref}
      className={classNames(Css.tab, active && Css.active, !count && Css.completed)}
      onClick={handleClick}>
      <span className={Css.label}>{label}</span>
      {!!count && showBadge && <Badge counter className={Css.badge}>{count}</Badge>}
    </div>
  );
};

const MonthsTabs = ({ data, disabled, className }) => {
  const { uiTexts } = useSelector(getTextsData);

  const [{ fromDate, toDate }, setEnvVars] = useEnvVars();

  const [envVars] = useEnvVars();

  const rootRef = useRef();

  const wrapperRef = useRef();

  const [translateX, setTranslateX] = useState(0);

  const [arrowsShown, setArrowsShown] = useState(false);

  const [leftArrowDisabled, setLeftArrowDisabled] = useState(false);

  const [rightArrowDisabled, setRightArrowDisabled] = useState(false);

  const tabs = useMemo(() => {
    const result = data
      .filter((item) => item.startDate)
      .reduce((accumulator, currentItem) => {
        const { startDate } = currentItem;

        const momentDate = moment.utc(startDate);

        const key = momentDate.format("YYYY-MM");

        const monthData = accumulator.find((item) => item.key === key);

        const uncompleted = currentItem.status !== DataConstants.TASK_STATUSES.COMPLETED;

        if (monthData) {
          if (uncompleted) monthData.count++;

          return accumulator;
        }

        return [...accumulator, {
          key,
          count: uncompleted ? 1 : 0,
          fromDate: momentDate.startOf("month").format(Constants.DATETIME_FORMATS.API_DATE),
          toDate: momentDate.endOf("month").format(Constants.DATETIME_FORMATS.API_DATE),
          label: moment(momentDate).format(MONTH_AND_YEAR_TEXT_EXT)
        }];
      }, []);

    return Utils.arraySort(result, "key", "asc");
  }, [data]);

  const style = useMemo(() => {
    return { transform: `translate(${translateX}px, 0)` };
  }, [translateX]);

  const handleLeftArrowClick = useCallback(() => {
    setTranslateX((prev) => {
      return Math.min(prev + rootRef.current.clientWidth - COMMON_TAB_WIDTH, 0);
    });
  }, []);

  const handleRightArrowClick = useCallback(() => {
    setTranslateX((prev) => {
      return Math.max(
        prev - rootRef.current.clientWidth + COMMON_TAB_WIDTH,
        rootRef.current.clientWidth - wrapperRef.current.scrollWidth
      );
    });
  }, []);

  const handleWindowResize = useCallback(() => {
    if (wrapperRef.current) {
      const hasInnerScroll = wrapperRef.current.scrollWidth > wrapperRef.current.clientWidth;

      setArrowsShown(hasInnerScroll);
      if (!hasInnerScroll) setTranslateX(0);
    }
  }, []);

  const handleActiveTab = useCallback((element) => {
    const tabRect = element.getBoundingClientRect();

    const rootRect = rootRef.current.getBoundingClientRect();

    setTranslateX(Math.min(0, rootRect.width - tabRect.left - rootRect.left));
  }, []);

  const handleTabClick = useCallback((params) => {
    setEnvVars(params);
  }, [setEnvVars]);

  useWindowResize(handleWindowResize);

  useEffect(() => {
    if (wrapperRef.current) {
      setArrowsShown(wrapperRef.current.scrollWidth > wrapperRef.current.clientWidth);
    }
  }, [tabs]);

  useEffect(() => {
    setLeftArrowDisabled(translateX === 0);
  }, [translateX]);

  useEffect(() => {
    if (wrapperRef.current && rootRef.current) {
      setRightArrowDisabled(
        translateX === (rootRef.current.clientWidth - wrapperRef.current.scrollWidth)
      );
    }
  }, [tabs, translateX]);

  useEffect(() => {
    setTranslateX(0);
  }, [tabs]);

  useEffect(() => {
    handleWindowResize();
  }, [tabs, envVars.editItem, envVars.editTask, handleWindowResize]);

  if (!tabs.length) return null;

  return (
    <div className={classNames(Css.monthsTabs, className)} disabled={disabled} ref={rootRef}>
      <div
        className={Css.wrapper}
        style={style}
        ref={wrapperRef}>
        <Tab
          showBadge={false}
          active={!fromDate && !toDate}
          data={{ label: uiTexts.all, count: 1 }}
          onClick={handleTabClick}
          onActive={handleActiveTab} />
        {tabs.map((tab) => (
          <Tab
            key={tab.key}
            data={tab}
            active={fromDate === tab.fromDate && toDate === tab.toDate}
            onClick={handleTabClick}
            onActive={handleActiveTab} />
        ))}
      </div>
      {arrowsShown && (
        <div className={Css.leftArrow} disabled={leftArrowDisabled} onClick={handleLeftArrowClick}>
          <Icons.CaretLeft />
        </div>
      )}
      {arrowsShown && (
        <div className={Css.rightArrow} disabled={rightArrowDisabled} onClick={handleRightArrowClick}>
          <Icons.CaretRight />
        </div>
      )}
    </div>
  );
};

export default React.memo(MonthsTabs);
